// pk_live_5vW0HJhDzyyVAaSOXvaKcbli
// sk_live_0GAfR4OnGqo240ZAlDBg0Xrc
// ca_CzK1uQ7VtfrflnYTkfQ4G6dbUgWCbpHf   //live
// ca_CzK10oQ5hbrBqN8zIB0zoq3ryx7C35rr    //stag
// sudo ssh -i kctickets meemansa@kctickets.online
export const FAKE_API = false;

// ca_GlkS98081c2iBU65bA9miJNLcWCrOoye


// live paths
// export const BASE_URL = 'https://kctickets.online:8000';     
// export const IMAGE_URL = 'https://kctickets.online:8000/api/image?filename='; 
// export const SOCKET_BASE_URL = 'https://kctickets.online:8000'; 
// export const LARGE_IMAGE_URL = 'https://storage.googleapis.com/kctickets-live-bucket/uploads/images/'; 
// export const MEDIUM_IMAGE_URL = 'https://storage.googleapis.com/kctickets-live-bucket/uploads/images/medium/'; 
// export const SMALL_IMAGE_URL = 'https://storage.googleapis.com/kctickets-live-bucket/uploads/images/small/'; 

//dev paths
export const BASE_URL = 'https://staging.kctickets.online:8000';     
export const IMAGE_URL = 'https://staging.kctickets.online:8000/api/image?filename='; 
export const SOCKET_BASE_URL = 'https://staging.kctickets.online:8000'; 
export const LARGE_IMAGE_URL = 'https://storage.googleapis.com/kctickets-staging/uploads/images/'; 
export const MEDIUM_IMAGE_URL = 'https://storage.googleapis.com/kctickets-staging/uploads/images/medium/'; 
export const SMALL_IMAGE_URL = 'https://storage.googleapis.com/kctickets-staging/uploads/images/small/'; 


//602208532633-jib6d7hllon5njniqhafsvkuaik3neas.apps.googleusercontent.com
// 338473084712-540tdd8o4tdki9sdluq04ktrnmo0tenk.apps.googleusercontent.com

  export const GOOGLE_API_KEY = '338473084712-540tdd8o4tdki9sdluq04ktrnmo0tenk.apps.googleusercontent.com';

  export const  BS_CONFIG = { dateInputFormat: 'YYYY-MM-DD', showWeekNumbers:false, isAnimated: true, adaptivePosition: true, displayOneMonthRange: true }

  export const  LIVING_REASONS = [
    'I was visiting and don’t plan on returning to the area.',
    'I never found an event or attraction I was interested in.',
    'I think the website is hard to navigate.',
    'I think it is too difficult create and post events or attractions.',
    'I could not get the website to work well or loads too slowly.',
    'I use a different website similar to this one.',
    'I had a bad experience with a vendor and/or the event or attraction listing.',
    'I have other reasons and will explain.',
      ];

